<template>
  <b-card body-class="p-0">
    <!-- <div class="load-conf" :class="{ 'd-none' : loadingHide }">
      <b-progress class="w-100" height="1.5rem" :max="maxLoadingTime">
        <b-progress-bar :value="loadingTime" :label="`${((loadingTime / maxLoadingTime) * 100).toFixed(2)}%`"></b-progress-bar>
      </b-progress>
    </div> -->
    <div class="custom-search">

      <div class="m-1">

        <!-- Table Top -->
        <b-row>
            <!-- 타이틀 -->
            <b-col
            cols="12"
            xl="12"
            lg="12"
            md="12"
            >
            <h4 class="w-100 font-weight-bolder mt-8px">코덱 관리</h4>
            </b-col>
            <!-- 타이틀 END -->
            <!-- 리스트 표시 갯수 설정 -->
            <!-- 리스트 표시 갯수 설정 END -->
        </b-row>

      </div>
    </div>
    <div class="pl-1 pr-1">
    <!-- table -->
        <vue-good-table
        :columns="columns"
        :rows="this.items"
        :rtl="direction"
        v-dragscroll="{ target: '.vgt-responsive' }"
        :pagination-options="{
            enabled: true,
            perPage:pageLength
        }"
        theme="my-theme"
        class="table good-table-custom table-light"
        >
        <div slot="emptystate">
          조회된 목록이 없습니다.
        </div>
        <template
            slot="table-row"
            slot-scope="props"
        >
            <!-- Column: Name -->
            <div v-if="props.column.field === 'sn'" class="text-nowrap">
                {{ props.row.sn }}
            </div>
            <div v-if="props.column.field === 'ip'" class="text-nowrap">
              <a :href="`https://${props.formattedRow.ip}`" target="_blank" style="color: #6e6b7b;">
                {{ props.formattedRow.ip }}
              </a>
            </div>
            <div v-if="props.column.field === 'confereneceNm'" class="text-nowrap">
                <a
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="setDetailProps(props.row, props.row.sn-1, 0)"
                v-b-toggle.cd-stu-detail
                >
                    {{ props.formattedRow.confereneceNm }} {{ props.row.sn-1}}
                </a>
            </div>
            <div v-if="props.column.field === 'areaNm'" class="text-nowrap">
                {{ props.formattedRow.areaNm }}
            </div>
            <div v-if="props.column.field === 'brandNm'" class="text-nowrap">
                {{ props.formattedRow.brandNm }}
            </div>
            <div v-if="props.column.field === 'modelNm'" class="text-nowrap">
                {{ props.formattedRow.modelNm }}
            </div>
            <div
            v-if="props.column.field === 'setting'"
            class="text-nowrap"
            >
                <a
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="setSettingProps(props.row, props.row.sn-1)"
                v-b-toggle.cd-stu-setting
                >
                    <button
                    style="border: 1px solid #eee; border-radius: 10px; padding: 8px; font-size : 10px;"
                    size="sm"
                    >
                    리셋
                    </button>
                </a>
            <!-- <a
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="setSettingProps(props.formattedRow.ip)"
                v-b-toggle.cd-stu-setting-new
                >
                    <button
                    class="btn btn-primary btn-sm"
                    size="sm"
                    >
                    설정
                    </button>
            </a> -->
            <!-- <a
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="setSettingProps(props.formattedRow.ip)"
                v-b-toggle.cd-stu-detail
                >
                    <button
                    class="btn btn-primary btn-sm"
                    size="sm"
                    >
                    상세
                    </button>
            </a> -->
            </div>
        </template>
        
        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
            <div class="d-flex justify-content-end flex-wrap">
            <div>
                <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="m-2"
                @input="(value)=>props.pageChanged({currentPage:value})"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
        </vue-good-table>
    </div>
    <codec-sttus-setting :detailItems="this.detailItem" :codecItem="this.codecItem" :index="this.index" :configSt="this.configSt"></codec-sttus-setting>
    <codec-sttus-setting-new :settingItems="this.settingItem" :codecItem="this.codecItem"></codec-sttus-setting-new>
    <codec-sttus-detail :detailItems="this.detailItem" :codecItem="this.codecItem" :codecItemType="this.codecItemTypes" :setTabs="this.setTab" :statusSt="this.statusSt"></codec-sttus-detail>
  </b-card>
</template>

<script>
import {
  BPagination, BRow, BCol, VBToggle, BCard, BFormGroup, BFormInput, BFormSelect, BProgress, BProgressBar
} from 'bootstrap-vue'
// BFormGroup, BFormInput, BFormSelect,

import Ripple from 'vue-ripple-directive'
import { dragscroll } from 'vue-dragscroll'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

import CodecSttusSettingNew from './CdStuSettingNew.vue'
import CodecSttusSetting from './CdStuSetting.vue'
import CodecSttusDetail from './CdStuDetail.vue'

const qs = require('qs')

export default {
  directives: {
    Ripple,
    dragscroll,
    'b-toggle': VBToggle,
  },
  components: {
    BCard,
    VueGoodTable,
    BFormGroup, 
    BFormInput, 
    BFormSelect,
    BPagination,
    BRow,
    BCol,
    BProgress,
    BProgressBar,
    // eslint-disable-next-line vue/no-unused-components
    CodecSttusSettingNew,
    CodecSttusSetting,
    CodecSttusDetail,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      loading: false,
      loadingTime: 0,
      maxLoadingTime: 3,
      loadingHide: true,
    //   codeAdvance,
      detailItem: {},
      settingItem: {},
      codecItem: {},
      codecItemTypes: 'success',
      setTab : 1,
      index: 0,
      columns: [
        { field: 'sn', label: '#', sortable: true, sortFn: this.sortFn,},
        // { key: 'clear', label: '초기값'},
        { field: 'ip', label: 'IP', sortable: true, sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: 'IP',
            trigger: 'enter',
          },
          width: '100px'
        },
        { field: 'confereneceNm', label: '회의실', sortable: true,  sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: '회의실',
            trigger: 'enter',
          },
        },
        { field: 'areaNm', label: '지역' , sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: '지역',
            trigger: 'enter',
          },
        },
        { field: 'brandNm', label: '브랜드', sortable: true, sortFn: this.sortFn,
         filterOptions: {
            styleClass: 'class1',
            enabled: true,
            placeholder: '브랜드',
            trigger: 'enter',
          },
          width: '50px'
        },
        { field: 'modelNm', label: '모델', sortable: true, sortFn: this.sortFn,
           filterOptions: {
            enabled: true,
            placeholder: '모델',
            trigger: 'enter',
          },
        },
        // { key: 'onoffSt', label: 'On/Off'},
        // { key: 'faultSt', label: 'Fault'},
        // { key: 'activationSt', label: 'Activ'},
        // { key: 'usageSt', label: 'Usage'},
        { field: 'setting', label: '설정'},
        // { key: 'manager', label: '담당자'},
        // { key: 'reservDt', label: '예약일자'},
        // { key: 'reservStartDt', label: '예약시작'},
        // { key: 'reservEndDt', label: '예약종료'},
      ],
      list: this.items,
      rows: [],
      searchTerm: '',
      statusSt: false,
      configSt: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    loading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.clearLoadingTimeInterval()

        if (newValue) {
          this.$_loadingTimeInterval = setInterval(() => {
            this.loadingTime++
          }, 1000)
        }
      }
    },
    loadingTime(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === this.maxLoadingTime) {
          this.loading = false
        }
      }
    }
  },
  created() {
    this.$_loadingTimeInterval = null
  },
  mounted() {
    
  },
  methods: {
    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval)
      this.$_loadingTimeInterval = null
    },
    startLoading() {
      this.loadingHide = false
      this.loading = true
      this.loadingTime = 0
    },
    advanceSearch(val) {
      this.searchTerm = val
    },
    setSettingProps(item,i) {
      // this.startLoading()
      this.configSt = false
      const param = {
        ip: item.ip
      }
      const codecUrl = 'api/codec/configuration'
      this.$nBack({
          method: 'post',
          url: codecUrl,
          data: qs.stringify(param)
        })
        .then((response) => {
          // console.log("*************************************", response.data.data[0].Configuration)
          if(response.data.resultCode === 'success'){
            this.configSt = true
            this.codecItem = response.data.data[0].Configuration
          }else{
            this.configSt = false
          }
          // alert('장비가 정상적으로 체크되었습니다.')
        })
        .catch(() => {
          // console.log(error)
          // alert(`${item.ip}의 장비 연결에 실패했습니다.`)
          this.configSt = false
        })
      // console.log(item)
      this.detailItem = item
    },
    setDetailProps(item, i, tabs) {
        // this.startLoading()
        this.statusSt = false
        this.detailItem = item
        this.index = i
        this.codecItemTypes = ''
        this.setTab = tabs
        // console.log('Item TEST 1', this.settingItems)
        // const codecUrl = `api/codec/status?pw=${item.codecPassword}&sn=${item.sn}&ip=${item.ip}`
        const codecUrl = 'api/codec/status'
        const param = {
          ip: item.ip
        }
        this.$nBack({
          method: 'post',
          url: codecUrl,
          data: qs.stringify(param)
        })
        .then((response) => {
          // console.log("*************************************", response.data.data[0])
          if(response.data.resultCode === 'success'){
            this.statusSt = true
            this.codecItem = response.data.data[0]
            this.codecItemTypes = response.data.resultCode
            this.loadingHide = true
          }else{
            this.statusSt = false
          }
          
          // alert('장비가 정상적으로 체크되었습니다.')
        })
        .catch(() => {
          // console.log(error)
          
          
          // alert('장비 연결에 실패했습니다.')
          // alert('')
        })
    },
    sortFn(x, y) {
        // x - row1 value for column
        // y - row2 value for column
        // col - column being sorted
        // rowX - row object for row1
        // rowY - row object for row2
        return (x < y ? -1 : (x > y ? 1 : 0));
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>