<template>
  <section id="dashboard-main" class="mt-2">
    <b-row class="match-height">
      <b-col xl="12" lg="12" md="12" class="ecs-card">
        <codec-mng-list :items="item"></codec-mng-list>
      </b-col>
    </b-row>
    <!-- <codec-sttus-list :items="this.item"></codec-sttus-list> -->
    <!-- <codec-sttus-setting :settingItems="this.settingItem"></codec-sttus-setting>
    <codec-sttus-setting-new :settingItems="this.settingItem"></codec-sttus-setting-new>
    <codec-sttus-detail :detailItems="this.detailItem"></codec-sttus-detail> -->
  </section>
</template>
<script>
import { BRow, BCol } from "bootstrap-vue";
// import {
//   VBToggle,
// } from 'bootstrap-vue'
// import Ripple from 'vue-ripple-directive'

// import { $codecConfig }  from '@ecsConfig'
// import CodecData from '@/assets/api/codec.ts'

import CodecMngList from "./components/CdMngList.vue";
// import CodecSttusSetting from './components/CdStuSetting.vue'
// import CodecSttusSettingNew from './components/CdStuSettingNew.vue'
// import CodecSttusDetail from './components/CdStuDetail.vue'

const qs = require("qs");

export default {
  components: {
    BRow,
    BCol,
    CodecMngList,
    // CodecSttusSetting,
    // CodecSttusSettingNew,
    // CodecSttusDetail,
  },
  data() {
    return {
      nowTime: new Date().getTime(),
      webdata: [],
      data: {},
      codecUrl: "api/codec/servers/",
      // codecUrl: '/d.json',
      item: [],
      wsItem: [],
      settingItem: {},
      detailItem: {},
    };
  },
  // setup() {

  //   return {
  //     resolveInvoiceStatusVariantAndIcon,
  //   }
  // },
  watch: {
    /* 'webdata': function () {
      console.log("webdata", this.webdata)
      for (let i = 0; this.webdata.length > i; i += 1) {
        for (let l = 0; this.item.length > l; l += 1) {
          if (this.webdata[i].data.diagnotics === 'onoff') { // onoff
            if (this.item[l].ip === this.webdata[i].data.ip) { // ip가 같으면 onoffSt 변경
              // console.log(this.webdata[i].data)
              // console.log(this.webdata.findIndex(i => this.webdata[i].data.ip === this.webdata[i].data.ip))
              // console.log(i)
              this.item[l].onoffSt = this.webdata[i].data.status
              if (this.item[l].onoffSt === 2) { // off일 때 faultSt, usageSt 상태 set
                this.item[l].faultSt = null
                this.item[l].usageSt = null
              }
            }
            // console.log("SN", this.webdata[i].data.sn)
          }
          if (this.webdata[i].data.diagnotics === 'fault') { // fault
            if (this.item[l].ip === this.webdata[i].data.ip) { // ip가 같으면 onoffSt 변경
            }
            // console.log("SN", this.webdata[i].data.sn)
          }
          if (this.webdata[i].data.diagnotics === 'usage') { // usage
            if (this.item[l].ip === this.webdata[i].data.ip) { // ip가 같으면 onoffSt 변경
            }
            // console.log("SN", this.webdata[i].data.sn)
          }
        } 
      } 
    } */
  },
  created() {
    // setInterval(() => {
    // this.jsonChangeTest(this.getCodec)
    // }, 5000)
  },
  mounted() {
    this.getCodec();
    this.wsConect();
    this.$store.commit("CHANG_COMP", 1);

    // console.log(this.item) // 처음 불러오는 data
    // console.log(this.webdata) // 웹소켓 data
    // console.log($codecConfig)
    // console.log($codecSerchConfig)
    // console.log('CODEC ITEM TEST', CodecData)
  },
  methods: {
    diffGetTime(date1) {
      let nowTime = new Date().getTime();
      let intime = new Date(date1).getTime();
      // let testtime1 = new Date('2021-02-28 09:52:00').getTime()
      // let testtime2 = new Date('2021-02-28 09:51:00').getTime()
      let diff = nowTime - intime;
      let rslt = false;
      if (diff > 60000) {
        rslt = false;
      } else {
        rslt = true;
      }
      return rslt;
      // return diff
    },
    resolveInvoiceStatusVariantAndIcon(status) {
      if (status === 1) return "badge-success";
      if (status === 2) return "badge-danger";
      if (status === null) return "badge-secondary";
      return "badge-secondary";
    },
    // setData(c) {
    //   let index = 0
    //   this.item.find((item, i)=>{
    //     if(item.ip === c.data.ip){
    //       index = i
    //       return i
    //     }
    //   })
    //   if(c.data.diagnotics==='onoff'){
    //       this.item[index].onoffSt = c.data.status
    //       // this.item[index].frstRegistPnttm = c.data.tm
    //       this.item[index].lastUpdtPnttm = c.data.tm
    //       this.diffGetTime(c.data.tm, this.item[index].lastUpdtPnttm)
    //   }else if(c.data.diagnotics==='act'){
    //       this.item[index].onoffSt = 1
    //       this.item[index].usageSt = c.data.status
    //   }
    // },
    wsConect() {
      this.$options.sockets.onmessage = (evt) => {
        // console.log("this.$options.sockets")
        // this.setData(JSON.parse(evt.data ))
      };
    },
    getCodec() {
      this.$DJsonNewHttps.post(this.codecUrl).then((response) => {
        // console.log('TEST 1', response);
        if (response.data) {
          if (
            response.data.resultCode == "success" &&
            response.data.resultMessage == "성공"
          )
            this.item = response.data.data;
        }
      });
      // this.item = $codecConfig
    },
    setSettingProps(item, i) {
      // console.log(item)
      this.settingItem = {
        data: item,
        index: i,
      };
    },
    setDetailProps(item) {
      this.detailItem = item;
      // console.log('Item TEST 1', this.settingItems)
      // const codecUrl = `api/codec/status?pw=${item.codecPassword}&sn=${item.sn}&ip=${item.ip}`
      const codecUrl = "api/codec/status";
      const param = {
        pw: item.codecPassword,
        sn: item.sn,
        ip: item.ip,
      };
      this.$nBack({
        method: "post",
        url: codecUrl,
        data: qs.stringify(param),
      })
        .then(() => {
          // console.log("*************************************", response)
          alert("장비가 정상적으로 체크되었습니다.");
        })
        .catch(() => {
          // console.log(error)
          alert("장비 연결에 실패했습니다.");
          // alert('')
        });
    },
    setOnOff() {
      // console.log(this.item)
      // console.log(this.webdata)
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
<style scoped>
table {
  width: 100%;
  border: 1px solid #dfdfdf !important;
  border-collapse: collapse;
}
th,
td {
  border: 1px solid #dfdfdf !important;
  font-size: 13px !important;
}
td:nth-child(1) {
  text-align: center;
}
td:nth-child(2),
td:nth-child(3),
td:nth-child(4),
td:nth-child(5),
td:nth-child(6) {
  padding-left: 5px;
  padding-right: 5px;
}
td:nth-child(6) {
  font-size: 11px;
}
</style>